import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getDoc, collection, updateDoc, onSnapshot, doc } from "firebase/firestore"; 
import { db } from "../firebase";
import LeftArrow from "../assets/left.png";
import CheckImage from "../assets/sent.png";
import styled, {keyframes} from 'styled-components';
import Lottie from "lottie-react";
import Celebrate from "../assets/celebrate.json";
import Fire from "../assets/fire.json";
import Listening from "../assets/instagram_animation_1.json";
import axios from "axios";
import {Helmet} from "react-helmet";
import sendToFriends from "../assets/send-to-friends.svg";
import Plus from "../assets/circle-plus-regular.svg";

const MessageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: auto;

  /* Add fading effect at the bottom */
  mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 70%, rgba(0,0,0,0) 88%);
  -webkit-mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 70%, rgba(0,0,0,0) 88%);
`;

const ResponsiveContainer = styled.div`
  width: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 15px;
  border-radius: 22px;
  backdrop-filter: blur(10px);
  -webkit-overflow-scrolling: touch; /* Enables momentum scrolling on iOS */
  overflow-y: auto;
  height: 65vh; /* Adjust as needed */
  margin-top: 15px;

  /* Hide scrollbar for Webkit browsers */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for other browsers */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

`;

const ResponseItem = styled.div`
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ResponseItemJoin = styled.div`
  padding: 10px;
  padding-left: 15px;
  padding-right: 20px;
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 15px;
  justify-content: space-between;
  align-items: center;
`;

const ResponseText = styled.span`
  color: #333;
  font-size: 17px;
  font-weight: 600;
`;

const ResponseTextJoin = styled.span`
  color: #333;
  font-size: 19px;
  font-weight: bold;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const Button = styled.button`
  background-color: #fff;
  border: none;
  border-radius: 15%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 35px;
  margin-left: 10px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  &:focus {
    outline: none;
  }
`;

const Counter = styled.span`
  margin-left: 0px;
  font-size: 18px;
  font-weight: bold;
`;

const ThumbsUpIcon = styled.span`
  color: green;
  font-size: 18px;
`;

const ThumbsDownIcon = styled.span`
  color: red;
  font-size: 18px;
`;

const BottomContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  gap: 25px;
  padding: 10px 0px 20px; /* Optional: Add padding */
`;

const breatheAnimation = keyframes`
  0% { background-color: gray; }
  50% { background-color: black; }
  100% { background-color: gray; }
`;

// Create a styled button with the breathing effect
const BreathingButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  ${'' /* animation: ${breatheAnimation} 5s infinite; */}
`;

const Sent = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const name = params.username || state?.name || '';
  const time = params.epoch || state?.time || '';
  const [pub, setPub] = useState(state?.pub || '');
  const [question, setQuestion] = useState(state?.question || '');
  const [questionType, setQuestionType] = useState(state?.questionType || '');
  const [photo, setPhoto] = useState(`https://firebasestorage.googleapis.com/v0/b/honest-c986c.appspot.com/o/profilePictures%2FprofPic-${name}.jpg?alt=media`);
  const [responses, setResponses] = useState([]);
  const [count, setCount] = useState(20);
  const [color, setColor] = useState("#E25967");
  const [buttonColor, setButtonColor] = useState("#E25967");
  const [ip, setIp] = useState("");
  const [loading, setLoading] = useState(true);
  const [backButton, setBackButton] = useState(true);

  const handleOnError = () => {
    setPhoto("");
  };

  const getIP = async () => {
    let ip = await axios
      .get("https://ipapi.co/json/")
      .then((res) => {
        return res.data.ip;
      })
      .catch((error) => console.log(error));
    return ip;
  }

  const handleThumbsUp = async (epoch, currentUp, currentDown) => {
    let ip = await getIP();

    const docRef = doc(db, "responses", String(name), String(time), String(epoch));
    const docSnapshot = await getDoc(docRef);

    // Update the 'down' counter
    let up = currentUp ? currentUp : 0;
    let down = currentDown ? currentDown : 0;

    if (docSnapshot.exists()) {
        let votes = docSnapshot.data().votes || [];

        // Check if the IP already exists in the votes array
        const existingVoteIndex = votes.findIndex(vote => Object.keys(vote)[0] === ip);

        if (existingVoteIndex > -1) {
            // Update the existing vote to "up"
            if (votes[existingVoteIndex][ip] && votes[existingVoteIndex][ip] == "down") {
              down = currentDown - 1;
              up = currentUp + 1;
            } else {
              up = currentUp;
            }
            votes[existingVoteIndex][ip] = "up";            
        } else {
            // Add a new vote as "up"
            votes.push({ [ip]: "up" });
            up = currentUp ? currentUp + 1 : 1;
        }        

        // Update the document with the new votes array and down count
        await updateDoc(docRef, {
            up: up,
            down: down,
            votes: votes
        });
    } else {
        console.error("Document does not exist");
    }
};

  const handleThumbsDown = async (epoch, currentDown, currentUp) => {
    let ip = await getIP();

    const docRef = doc(db, "responses", String(name), String(time), String(epoch));
    const docSnapshot = await getDoc(docRef);

    // Update the 'down' counter
    let down = currentDown ? currentDown : 0;
    let up = currentUp ? currentUp : 0;

    if (docSnapshot.exists()) {
        let votes = docSnapshot.data().votes || [];

        // Check if the IP already exists in the votes array
        const existingVoteIndex = votes.findIndex(vote => Object.keys(vote)[0] === ip);

        if (existingVoteIndex > -1) {
            // Update the existing vote to "down" and decrement the "up" count
            if (votes[existingVoteIndex][ip] && votes[existingVoteIndex][ip] == "up") {
              up = currentUp - 1;
              down = currentDown + 1;
            } else {
              down = currentDown;
            }
            votes[existingVoteIndex][ip] = "down";    
        } else {
            // Add a new vote as "down"
            votes.push({ [ip]: "down" });
            down = currentDown ? currentDown + 1 : 1;
        }        

        // Update the document with the new votes array and down count
        await updateDoc(docRef, {
            down: down,
            up: up,
            votes: votes
        });
    } else {
        console.error("Document does not exist");
    }
};


  const weightUpvote = 1.2;
  const weightDownvote = 1.0;
  const significantDownvoteFactor = 3; // Adjust this factor as needed
  const votePresenceWeight = 1000; // Large number to ensure responses with votes are ranked higher

  const RankingAlg = (resp) => {
    const upvotes = resp.data().up ? resp.data().up : 0;
    const downvotes = resp.data().down ? resp.data().down : 0;
  
    // Check if there are no votes
    if (upvotes === 0 && downvotes === 0) {
      return -Infinity; // Lowest possible rank for no votes
    }
  
    // Calculate the total number of votes
    const totalVotes = upvotes + downvotes;
  
    // Calculate the score considering total votes and weighted difference
    return (totalVotes * 2.5) + (upvotes * weightUpvote) - (downvotes * weightDownvote) + votePresenceWeight;
  };

  const RankingAlgTesting = (resp) => {
    const upvotes = resp.up ? resp.up : 0;
    const downvotes = resp.down ? resp.down : 0;
  
    // Check if there are no votes
    if (upvotes === 0 && downvotes === 0) {
      return -Infinity; // Lowest possible rank for no votes
    }
  
    // Calculate the total number of votes
    const totalVotes = upvotes + downvotes;
  
    // Calculate the score considering total votes and weighted difference
    return (totalVotes * 2.5) + (upvotes * weightUpvote) - (downvotes * weightDownvote) + votePresenceWeight;
  };
  

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, 'responses', String(name), String(time)),
      (snapshot) => {
        const newResponses = snapshot.docs
        .filter(doc => doc.data().response) 
        .sort((a, b) => {
          // Calculate scores using RankingAlg
          const scoreA = RankingAlg(a);
          const scoreB = RankingAlg(b);
      
          // Check for significantly higher downvotes
          if (a.downvotes > b.upvotes * significantDownvoteFactor) return -1;
          if (b.downvotes > a.upvotes * significantDownvoteFactor) return 1;
      
          // Sort based on scores
          return scoreB - scoreA;
        }).map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setResponses(newResponses);
      },
      (error) => {
        console.error("Error getting documents: ", error);
      }
    );

    if (questionType === 1) {
      setColor("#2CD27E");
      setButtonColor("#2CD27E");
    } else if (questionType === 2) {
      setColor("#F88379");
      setButtonColor("#F88379");
    } else if (questionType === 3) {
      setColor("#26A1D5");
      setButtonColor("#26A1D5");
    } else if (questionType === 4) {
      setColor("#949494");
      setButtonColor("#949494");
    } else if (questionType === 5) {
      setColor("#D042F8");
      setButtonColor("#D042F8");
    } else if (questionType === 6) {
      setColor("#EC1254");
      setButtonColor("#EC1254"); 
    } else {
      //default
      setButtonColor("#E25967");
    }

    // Cleanup the listener on component unmount
    return () => unsubscribe();
  }, []);


  useEffect(() => {
    async function openPage() {

      const url = window.location.href;
      const isWordInUrl = url.includes("convo");
      if (isWordInUrl && params.username && params.epoch) {
        setLoading(true);
        setBackButton(false);
        fetchData();
      } else {
        setLoading(false);
      }
      if (pub) {
        setIp(await getIP());
      }
    }    
    openPage();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => {
        const randomNum = Math.floor(Math.random() * 6);

        switch (randomNum) {
          case 0:
            return prevCount + 1 > 0 ? prevCount + 1 : 0;
          case 1:
            return prevCount + 2 > 0 ? prevCount + 2 : 0;
          case 2:
            return prevCount + 3 > 0 ? prevCount + 3 : 0;
          case 3:
            return prevCount - 1 > 0 ? prevCount - 1 : 0;
          case 4:
            return prevCount - 2 > 0 ? prevCount - 2 : 0;
          case 5:
            return prevCount - 3 > 0 ? prevCount - 3 : 0;
          default:
            return prevCount;
        }
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const hasVoted = (votes, ip, voteType) => {
    return votes.some(vote => vote[ip] === voteType);
  };

  const fetchData = async () => {
    setLoading(true);
    var color = "#EC1187 linear-gradient(to bottom right, #EC1187 0%, #FF8D10 100%)";
    var buttonColor = "#E25967";
    try {
      const docSnap = await getDoc(doc(db, "questions", params.username, "allQuestions", params.epoch));
      const data = docSnap.data();
      if (data) {
        setPub(data.public);
        setQuestion(data.question);
        setQuestionType(data.questionType);
        setIp(await getIP());

        if (data.questionType == 1) {
          color = ("#2CD27E");
          buttonColor = ("#2CD27E");
        } else if (data.questionType == 2) {
          color = ("#F88379");
          buttonColor = ("#F88379");
        } else if (data.questionType == 3) {
          color = ("#26A1D5");
          buttonColor = ("#26A1D5");
        } else if (data.questionType == 4) {
          color = ("#949494");
          buttonColor = ("#949494");
        } else if (data.questionType == 5) {
          color = ("#D042F8");
          buttonColor = ("#D042F8");
        } else if (data.questionType == 6) {
          color = ("#EC1254");
          buttonColor = ("#EC1254");
        }
        setLoading(false);
      } else {
        // await postQuestion(qData);
        setLoading(false);
      }
      setColor(color);
      setButtonColor(buttonColor);
      document.documentElement.style.background = color;
    } catch {
      setLoading(false);
      // console.error("error");
    }
  };

  return (
    <>  
        <Helmet>
            <meta charSet="utf-8" />
            <title>@{name}: {question}</title>
            <meta name="description" content="Let's be honest...Join the conversation!" />
        </Helmet>
          {params.username && params.epoch && loading ? (
        <div className="loading">
          <span className="loader"></span>
        </div>
      ) : (
            <>
    <Lottie style={{width: '100%', position: 'absolute', zIndex: -1}} animationData={Celebrate} loop={false} />               
      <div className={"container"}>   
      <div style={pub ? {marginTop: 20, zIndex: 2000, height: 50} : null} className="navbar">
          {true ? <div className="back" onClick={() => navigate("/" + name + "/" + time)}>
            <img src={LeftArrow} alt="back" width="24" height="24" />
          </div> : <BreathingButton onClick={() => {
            alert("All responses, up votes, and down votes are updating live! Leave this page open to see changes in real time.")
          }} style={{zIndex: 2001, borderRadius: '100px', paddingTop: 0, paddingBottom: 0, paddingLeft: 10, paddingRight: 20, backgroundColor: "rgba(255,255,255,0.1)", color: "white", position: 'absolute', left: 0}} className="back">
            <div style={{ display: 'flex', alignItems: 'center',}}>
               {/* <Lottie style={{width: 45, height: 45, paddingBottom: 3}} animationData={Plus} loop={true} />     */}
               {/* <img src={Plus} fill="#ffffff" alt="send" width="24" height="24" /> */}
              <div style={{fontWeight: "bold", fontSize: 16}}>Add your own reply</div>
            </div>
          </BreathingButton>}
          {pub ? <BreathingButton onClick={() => {
            // alert("All responses, up votes, and down votes are updating live! Leave this page open to see changes in real time.")
            navigate("/" + name + "/" + time);
          }} style={{zIndex: 2001, borderRadius: '100px', paddingTop: 0, paddingBottom: 0, paddingLeft: 10, paddingRight: 20, backgroundColor: "rgba(255,255,255,0.1)", color: "white", position: 'absolute', right: '70px'}} className="back">
            <div className="pulse" style={{ display: 'flex', alignItems: 'center', paddingTop: 12, paddingBottom: 12}}>
              <img src={Plus} style={{color: "white", marginLeft: 5, fill: "white", stroke: "white"}} stroke="white" fill="white" width="20" height="20" />
               {/* <Lottie style={{width: 45, height: 45, paddingBottom: 3, color: 'white'}} animationData={Plus} loop={true} />     */}
              <div style={{fontWeight: "bold", fontSize: 16, marginLeft: 7}}>Add your reply</div>
            </div>
          </BreathingButton> : null}
          {pub ? <div style={{width: '200', borderRadius: '100px', paddingTop: 10, paddingBottom: 10, paddingLeft: 20, paddingRight: 20, backgroundColor: "rgba(255,255,255,0.1)", color: "white", position: 'absolute', right: 0}} className="back" onClick={async () => {
            try {
                await navigator.share({
                  title: "@" + name + ": " + question,
                  url: "https://honest.link/" + name + "/" + time,
                  text: "Join this anonymous conversation!"
                });
                console.log('Successfully shared');
              } catch (error) {
                console.error('Error sharing:', error);
              }
          }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={sendToFriends} fill="#ffffff" alt="send" width="24" height="24" />
            </div>
          </div> : null}
        </div>         
        <div className="check">
          {pub ? null : <img src={CheckImage} alt="sent" />}
          {pub ? <div className="container" style={{marginTop: -35, width: '100%'}}>
            <form className="form" method="post">
              <div className="bubble">
                <div className="header" style={{borderRadius: 22}}>
                  {photo ? <div className="pfp-container" style={{ backgroundImage: photo }}>
                    {photo && <img src={photo} alt="" onError={handleOnError} />}
                  </div> : null}
                  <div style={!photo ? {marginLeft: 10} : null} className="user-container">
                    <div className="username">@{name}</div>
                    <div className="prompt">
                      <div className="text" style={{fontSize: 15, fontWeight: '700'}}>{question}</div>
                      {/* {qData.link && (
                        <Link to={qData.link} className="link">
                          <div className="link-text">{qData.link}</div>
                        </Link>
                      )} */}
                    </div>
                  </div>
                </div>
                {/* <div className="textarea-container">
                  <textarea placeholder={pub ? "Reply to see other anonymous responses 👀" : "Reply anonymously here"} value={question} name="question" autoComplete="off" maxLength="300" />
                </div> */}
              </div>
              {/* <div className="anonymous-tooltip">Your response is 100% anonymous 🔒</div> */}
              {/* {text && (
                <button className="submit" type="submit" onClick={Submit} disabled={load}>
                  Send!
                </button>
              )} */}
            </form>
            <MessageContainer>
              <ResponsiveContainer>
                {responses.map((response, index) => {
                  const hasVotedUp = response.votes ? (hasVoted(response.votes, ip, 'up')) : false;
                  const hasVotedDown = response.votes ? (hasVoted(response.votes, ip, 'down')) : false;

                  {/* console.log("votes", response.votes, ip, response.votes[String(ip)], hasVotedDown); */}
                  return (
                    <>
                    {/* <ResponseItem key={index}>{response.response}</ResponseItem> */}
                    <ResponseItem key={index}>
                      <ResponseText>{response.response}</ResponseText>
                      <ButtonsContainer>
                        <Button style={{backgroundColor: hasVotedUp ? buttonColor : null}} onClick={() => handleThumbsUp(response.epoch, response.up, response.down)}>
                          <ThumbsUpIcon style={response.up ? {marginRight: 5} : null}>👍 {hasVotedUp}</ThumbsUpIcon>
                          {response.up ? <Counter style={hasVotedUp ? {color: "white"} : {color: "black"}}>{response.up}</Counter> : null}
                        </Button>
                        <Button style={{backgroundColor: hasVotedDown ? buttonColor : null}} onClick={() => handleThumbsDown(response.epoch, response.down, response.up)}>
                          <ThumbsDownIcon style={response.down ? {marginRight: 5} : null}>👎</ThumbsDownIcon>
                          {response.down ? <Counter style={hasVotedDown ? {color: "white"} : {color: "black"}}>{response.down}</Counter> : null}
                        </Button>
                      </ButtonsContainer>
                    </ResponseItem>
                    </>)
                })}
                <ResponseItemJoin onClick={() => {
                    navigate("/" + name + "/" + time);
                  }} style={{textAlign: 'center', padding: 30}} key={"final"}>
                      <ResponseTextJoin style={{textAlign: 'center', textDecoration: "underline"}}>{"Add your own reply +"}</ResponseTextJoin>                      
                </ResponseItemJoin>
                <div style={{height: 120}}></div>
              </ResponsiveContainer>
            </MessageContainer>
          </div> : <div style={{ textAlign: "center" }} className="sent">
            Sent anonymously to {name}!
          </div>}
        </div>
        {pub ? 
          <BottomContainer>
            <Link
              style={{marginBottom: -10, width: '85%', fontSize: 19}}
              className="button download-link pulse"
              to="https://apps.apple.com/us/app/honest-link/id6464457576"
              target="_blank"
            >
              {pub ? "Start your own conversation 💬" : "Get your own responses!"}
            </Link>
            <div style={{ textAlign: "center", fontSize: 17, fontWeight: 'bold', color: "white" }} className="back">
              {/* {`${count} people just tapped the button 👆`} */}
            </div>
          </BottomContainer>  
          : 
          <div className="message-container">          
            <Link className="button download-link pulse" to="https://apps.apple.com/us/app/honest-link/id6464457576" target="_blank">
              Get your own responses!
            </Link>
            <div style={{ textAlign: "center", fontSize: 17 }} className="back">
              {`${count} people just tapped the button 👆`}
            </div>         
          </div>}
      </div></>)}  
      {/* <div className="bottom">
        <Link
          target="_blank"
          className="button-small button-translucent rizz-button"
          to="https://apps.apple.com/us/app/honest-link/id6464457576"
        >
          <img src={Logo} alt="logo" width="130" />
        </Link>
      </div> */}
    </>
  );
};

export default Sent;