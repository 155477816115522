import React from "react";
import Logo from "../assets/logo.png";

const Privacy = () => {
  return (
    <>
      <div className="w-section">
        <div className="w-container">
          <br />
          <div className="heading">Privacy Policy</div>
          <p class="paragraph" style={{ marginLeft: 25, marginRight: 25 }}>
            Last Updated: January 9th, 2024
            <br />
            <br />
            <b>Introduction</b>
            <br />
            Welcome to Honest Link. We are committed to protecting your privacy. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of your information through the use of our website and service.
            <br />
            <br />
            <b>Information Collection and Use</b>
            <br />
            We collect information from you when you register on our site, subscribe to our newsletter, fill out a form, or enter information on our site. The information collected may include your name, email address, phone number, or other personal details. We use this information to personalize your experience, improve our website, provide customer service, process transactions, send periodic emails, and for other purposes described at the time of collection.
            <br />
            <br />
            <b>Cookies and Tracking Technologies</b>
            <br />
            Our site may use "cookies" to enhance user experience. Your web browser places cookies on your hard drive for record-keeping purposes and sometimes to track information about them. You may choose to set your web browser to refuse cookies or to alert you when cookies are being sent. Note that some parts of the Site may not function properly without them.
            <br />
            <br />
            <b>Data Security</b>
            <br />
            We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information.
            <br />
            <br />
            <b>Sharing of Information</b>
            <br />
            We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless we provide you with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.
            <br />
            <br />
            <b>Third Party Links</b>
            <br />
            Occasionally, at our discretion, we may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies. We, therefore, have no responsibility or liability for the content and activities of these linked sites.
            <br />
            <br />
            <b>Your Consent</b>
            <br />
            By using our site, you consent to our privacy policy.
            <br />
            <br />
            <b>Changes to our Privacy Policy</b>
            <br />
            If we decide to change our privacy policy, we will update the Privacy Policy modification date above.
            <br />
            <br />
            <b>Contacting Us</b>
            <br />
            If there are any questions regarding this privacy policy, you may contact us using the information below.
            <br />
            <br />
            Honest Link
            <br />
            523 N Crescent Dr Beverly Hills CA 90210
            <br />
            jason@honest.link
            <br />
            +1-510-847-8748
          </p>
        </div>
        <div style={{ height: 100 }}></div>
      </div>
      {/* <img src={Logo} alt="logo" /> */}
      {/* <a class="w-webflow-badge" href="https://webflow.com?utm_campaign=brandjs">
        <img src="https://d3e54v103j8qbb.cloudfront.net/img/webflow-badge-icon.f67cd735e3.svg" alt="" style={{ marginRight: "8px", width: "16px" }} />
        <img src="https://d1otoma47x30pg.cloudfront.net/img/webflow-badge-text.6faa6a38cd.svg" alt="Made in Webflow" />
      </a> */}
    </>
  );
};

export default Privacy;
